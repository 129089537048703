<template>
  <div class="modal fade" id="test-start" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header modal-header-blue-bg">
          <div class="title-with-icon">
            <div class="title-with-icon__icon title-with-icon__icon-white  title-with-icon__icon_exclamation"></div>
            <div class="title-with-icon__title">{{ test.title }}</div>
          </div>
          <button id="modal-close" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <test-start-info :test="test"/>
          <div class="row justify-content-start mt-64 test__footer-button">
            <div class="col-auto">
              <button @click="toTest()" class="btn btn-primary btn-with-icon">
                  <span>
                    Пройти тест
                    <img class="ml-1" src="/pic/icon-chevron-right.svg" />
                  </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TestStartInfo from "@/components/profile/test/TestStartInfo.vue";
import $ from "jquery";

export default {
  name: "ModalTestStart",
  components: {TestStartInfo},
  props: {
    test: {
      type: Object,
      required: true
    }
  },
  methods: {
    async toTest() {
      await $('#test-start').modal('hide')
      await this.$router.push('/lk/test/' + this.test.id + '?start=true').catch(() => {})
    }
  }
}
</script>

<style scoped>

</style>